// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "!pQVQPOOOOQO'#C`'#C`O_QQO'#C_OOQO'#Ch'#ChOOQO'#Cd'#CdQVQPOOOdQPO,58yOOQO-E6b-E6bOOQO'#Cc'#CcOOQO1G.e1G.eOlQSO1G.eOgQPO7+$POOQO<<Gk<<Gk",
  stateData: "q~OZOS~OQRO]PO~OTUO~O^YO_WO~OUZO~O",
  goto: "v]PPP^bPPflPPPrTROTTQOTQXUR[ZQTORVTTSOT",
  nodeNames: "⚠ GrokExpression String Pattern GrokStart GrokPattern GrokSemantic GrokEnd",
  maxTerm: 15,
  skippedNodes: [0],
  repeatNodeCount: 1,
  tokenData: "#c~R[XYwYZw]^wpqwuv|}!O!X!Q![!X![!]!o!c!}!t#R#S!X#T#o!t#q#r#^~|OZ~~!PP#o#p!S~!XO]~U!`TTQUS}!O!X!Q![!X!c!}!X#R#S!X#T#o!X~!tO^~V!}TTQUSQP}!O!X!Q![!X!c!}!t#R#S!X#T#o!t~#cO_~",
  tokenizers: [0, 1, 2],
  topRules: {"GrokExpression":[0,1]},
  tokenPrec: 0
})